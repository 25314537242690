import React from 'react'
import Layout from "../components/layout"
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import Smilesmall from '../images/smile-small.svg'
import CirclesBg from '../images/circles-bg.svg'
import Header from '../components/header'
import Cards from '../components/cards'
import Footer from '../components/footer'

export default () => (

  <Layout>
    <Helmet>
      <title>Frequently asked questions - Joycards</title>
      <meta name="description" content="Find out about Joycards and how our group video cards help share more joy than traditional greeting cards." />
    </Helmet>
    <Header />
    <section className='sub-page-banner'>
      <div className='container'>
        <div className='two-grid sub-page-head'>
          <div className='sub-left'>
            <h1>FAQs</h1>
          </div>
          <div className='sub-right'>
            <div className='bg-circles-sub'>
              <img src={CirclesBg} alt='circles for decoration' aria-hidden="true"/>
            </div>
            <div className='smile-img-sub'>
              <img src={Smilesmall} alt='smile icon' aria-hidden="true"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className='beige-bg section-wrapper'>
      <div className='container'>
        <div className='sub-page-content'>
          <div className='qa'>
            <h4>What is Joycards?</h4>
            <p>Joycards is a service that enables you to create group video cards for <Link to="/birthday-card">birthdays</Link>, <Link to="/wedding-greeting-card">weddings</Link>, <Link to="/farewell-cards">farewells</Link>, <Link to="/get-well-soon-card">get-well-soons</Link>, <Link to="/mothers-day-card">Mothers Day</Link> and all other times you want to send something that is more loved, and lasts longer, than a boring old greeting card or eCard.</p>
          </div>
          <div className='qa'>
            <h4>How does it work?</h4>
            <p>Simply <Link to="/how-to-1">sign up for free</Link> and create a video. Then invite your friends, family and colleagues to add videos to your card. When you've received all the videos simply send it to the recipient.</p>
          </div>
          <div className='qa'>
            <h4>How much does it cost?</h4>
            <p>Joycards is currently free to use. We will add paid features in future but for now enjoy sending more joy than a greeting card completely free <span role="img" aria-label="smiley face">😄</span>.</p>
          </div>
          <div className='qa'>
            <h4>How many people can contribute to a video card?</h4>
            <p>During the free pricing period the only restrictions we have is that you can have a maximum of 50 people contribute to your card. Plus, the max length of a video is 1 minute (we find videos between 15 - 30 seconds work best as part of a group video card).</p>
          </div>
          <div className='qa'>
            <h4>Can I save my videos?</h4>
            <p>During the free pricing period your videos will be available in your account. Plus, if the recipient of the Joycard chooses to open an account they will have access to their videos whenever they want to view them.</p>
          </div>
          <div className='qa'>
            <h4>Videos vs greeting cards and eCards?</h4>
            <p>Greeting cards are expensive, mostly impersonal and a pain to keep. <Link to="/">Joycards</Link> capture video greetings from many people in one place allowing the recipient to keep the video and watch it again and again in years to come.</p>
          </div>
        </div>
      </div>
    </section>
    <section className='cta'>
      <div className='container flex flex-jc'>
        <div className='cta-btn-wrapper'>
          <a href="https://app.joy.cards/accounts/signup/" target="_blank" rel="noopener noreferrer" className='btn btn-teal btn-lg cta-btn'>Start your card</a>
        </div>
      </div>
    </section>
    <Cards />
    <Footer />
  </Layout>
)
